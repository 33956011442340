jQuery(document).ready(function(){
  setUtmParamsToLinks();
});

function appendUtmParams(href){
  if(typeof href === 'undefined'){
    href = window.location.href;
  }

  var utmparams = getCookie('utms');
  var innerutmparams = '';

  if(href !== undefined && href.indexOf('utm_') === -1 && href.indexOf('#') !== 0) {
    innerutmparams = utmparams;
    if(href.indexOf('?') !== -1) {
      innerutmparams = '&' + utmparams;
    }else{
      innerutmparams = '?' + utmparams;
    }
    href = href + innerutmparams;
    href.replace('?&','?');
  }
  return href;
}

function getUtmsArr(utms){
  if(typeof utms !== 'undefined') {
    var utmArr = utms.split('&');
    var result = [];
    for(var x = 0; x < utmArr.length; x++){
      var curr = utmArr[x].split('=');
      result[curr[0]] = curr[1];
    }

    return result;
  } else {
    var result = [];
    result['utm_source'] = 'direct';
    result['utm_medium'] = 'organic';
    result['utm_term'] = '';
    result['utm_content'] = '';
    result['utm_campaign'] = '';

    return result;
  }

}

function setUtmParamsToLinks() {

	// manually add utm parameters on each links
    var utmparams = location.search;
    $('a').each(function() {
        var href = $(this).attr('href').replace(/\/+$/,'');
        if(href !== undefined && href.indexOf('utm_') === -1 && href.indexOf('#') !== 0) {
          href = href + utmparams;
          $(this).attr('href', href);
        }
    });

}

function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
};
